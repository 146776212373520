import { ApolloProvider } from '@apollo/client'
import { Authenticated, history, PageNotFound, PageServiceUnavailable } from '@shared-ui/auth'
import { useAuthStore } from '@shared-ui/auth/auth-store'
import { GlobalStyle, Loader, LoaderContainer, NavigationBar, Title, Vertical } from '@shared-ui/components'
import { AllUsersIcon } from '@shared-ui/components/CommonIcons'
import {
  AppContainer,
  HeaderContainer,
  MediaContainer,
  ScrollContainer,
  ScrollContent,
} from '@shared-ui/components/Containers'
import { ErrorBoundary } from '@shared-ui/components/ErrorBoundary'
import { Header } from '@shared-ui/components/header/Header'
import { BoxIcon, InboxIcon } from '@shared-ui/components/icons'
import { VerticalScroll } from '@shared-ui/components/Layout'
import { Shipments } from '@shared-ui/components/shipments'
import { RmaTickets } from '@shared-ui/components/Tickets'
import { TosAgreement } from '@shared-ui/components/TosAgreement'
import { useRequest } from '@shared-ui/hooks'
import { setPortal } from '@shared-ui/utils/isPortal'
import { setMacSpecialStrings } from '@shared/lib'
import { TosVersion } from '@shared/lib/constants'
import { DashboardIcon, SettingsIcon } from '@ubnt/icons'
import Footer from 'components/Footer'
import { RedirectTrailingSlash } from 'components/RedirectTrailingSlash'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { getApolloClient } from '../apollo'
import { CustomerTickets } from './customer-tickets/customer-tickets'
import { Dashboard } from './dashboard'
import { PolicyAndProcedures } from './documents/policy-and-procedures'
import { TermsOfService } from './documents/terms-of-service'
import { UserGuide } from './documents/user-guide'
import { Invitation } from './join'
import { Settings } from './settings/Settings'

setPortal('VAR', ' - Reseller Portal | Ubiquiti')

function Navigation() {
  return (
    <NavigationBar
      nav={[
        { icon: <DashboardIcon size="large" />, label: 'Dashboard', path: '/dashboard' },
        { icon: <AllUsersIcon />, label: 'Customer Tickets', path: '/customer-tickets' },
        { icon: <InboxIcon />, label: 'RMA Requests to Distributors', path: '/tickets' },
        { icon: <BoxIcon />, label: 'Shipments', path: '/shipments' },
      ]}
      bottom={[{ icon: <SettingsIcon />, label: 'Settings', path: '/settings' }]}
    />
  )
}

function AppBase({ children, inlineFooter }: { children: ReactNode; inlineFooter?: boolean }) {
  return (
    <ApolloProvider client={getApolloClient()}>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <MediaContainer>
        <Navigation />
        <ScrollContainer>
          <ScrollContent $inline={inlineFooter}>
            <ErrorBoundary>{children}</ErrorBoundary>
            <Footer />
          </ScrollContent>
        </ScrollContainer>
      </MediaContainer>
    </ApolloProvider>
  )
}

function DashboardView() {
  return (
    <AppBase>
      <Title title="Dashboard" />
      <Dashboard />
    </AppBase>
  )
}

function CustomerTicketsView() {
  return (
    <AppBase>
      <Title title="Customer Tickets" />
      <CustomerTickets />
    </AppBase>
  )
}

function SettingsView() {
  return (
    <AppBase inlineFooter>
      <Title title="Settings" />
      <Settings />
    </AppBase>
  )
}

function ShipmentsView() {
  return (
    <AppBase>
      <Title title="Shipments" />
      <Shipments />
    </AppBase>
  )
}

function TicketsView() {
  return (
    <AppBase>
      <Title title="RMA Requests to Distributors" />
      <RmaTickets label="RMA Requests to Distributors" />
    </AppBase>
  )
}

function CookiesPolicyView() {
  return (
    <AppBase>
      <Title title="Cookies Policy" />
      <Vertical style={{ padding: '30px 60px' }}>
        <div id="ot-sdk-cookie-policy" />
      </Vertical>
    </AppBase>
  )
}

function AppContent() {
  const { user } = useAuthStore()

  const [fetchOuiMacs, { loading: loadingOuiMacs, error: ouiMacsError }] = useRequest('ouiMacs')

  useEffect(() => {
    fetchOuiMacs({})
      .then(({ data }) => setMacSpecialStrings(data))
      .catch((e) => console.error(e))
  }, [])

  const isTosAccepted = user?.tosVersionAccepted === TosVersion
  if (!isTosAccepted) {
    return (
      <>
        <TosAgreement />
        <Footer />
      </>
    )
  }

  if (loadingOuiMacs || ouiMacsError) {
    return (
      <LoaderContainer>
        <Loader type={ouiMacsError ? 'error' : undefined} />
      </LoaderContainer>
    )
  }

  return (
    <Switch>
      <Route path="/dashboard">
        <DashboardView />
      </Route>
      <Route path="/customer-tickets">
        <CustomerTicketsView />
      </Route>
      <Route path="/tickets">
        <TicketsView />
      </Route>
      <Route path="/shipments">
        <ShipmentsView />
      </Route>
      <Route path="/settings">
        <SettingsView />
      </Route>
      <Route path="/cookies-policy">
        <CookiesPolicyView />
      </Route>
      <Route path="*">
        <Redirect to="/dashboard" />
      </Route>
    </Switch>
  )
}

function AppInvitation() {
  const { user } = useAuthStore()

  const isTosAccepted = user?.tosVersionAccepted === TosVersion
  if (!isTosAccepted) {
    return (
      <>
        <TosAgreement />
        <Footer />
      </>
    )
  }

  return <Invitation />
}

function HeaderWithoutSection({ children }: { children: ReactNode }) {
  return (
    <HeaderContainer>
      <Header loadAccountSection={false} />

      {children}
    </HeaderContainer>
  )
}

export function App() {
  return (
    <AppContainer>
      <GlobalStyle />
      <Router history={history}>
        <RedirectTrailingSlash />

        <Switch>
          <Route path="/join/:hash">
            <HeaderWithoutSection>
              <AppInvitation />
            </HeaderWithoutSection>
          </Route>

          <Route path="/terms-of-service">
            <HeaderWithoutSection>
              <VerticalScroll>
                <TermsOfService />
                <Footer />
              </VerticalScroll>
            </HeaderWithoutSection>
          </Route>

          <Route path="/policy-and-procedures">
            <HeaderWithoutSection>
              <PolicyAndProcedures />
              <Footer />
            </HeaderWithoutSection>
          </Route>

          <Route path="/user-guide">
            <HeaderWithoutSection>
              <UserGuide />
              <Footer />
            </HeaderWithoutSection>
          </Route>

          <Route path="/404">
            <HeaderWithoutSection>
              <PageNotFound />
            </HeaderWithoutSection>
          </Route>

          <Route path="/50x">
            <HeaderWithoutSection>
              <PageServiceUnavailable />
            </HeaderWithoutSection>
          </Route>

          <Switch>
            <Route>
              <Authenticated>
                <AppContent />
              </Authenticated>
            </Route>
          </Switch>
        </Switch>
      </Router>
    </AppContainer>
  )
}
