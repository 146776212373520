// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../generated/graphql'

import type { Shared_TicketFragment } from './Ticket'
import type { Shared_Ticket_DetailsFragment } from './Details'
import type { Shared_Ticket_CustomerFragment } from './Customer'

export type Shared_Ticket_ManageFragment = { readonly __typename?: 'Ticket' } & Pick<
  Types.Ticket,
  | 'id'
  | 'parentId'
  | 'status'
  | 'statuses'
  | 'distributorId'
  | 'flow'
  | 'warrantyStatus'
  | 'supportById'
  | 'supportByName'
  | 'destinationAddressId'
> & {
    readonly device: { readonly __typename?: 'Device' } & Pick<Types.Device, 'id'> & {
        readonly product: Types.Maybe<
          { readonly __typename?: 'Product' } & Pick<Types.Product, 'id' | 'rmaParentCategoryId'>
        >
      }
    readonly shipment: Types.Maybe<{ readonly __typename?: 'Shipment' } & Pick<Types.Shipment, 'id' | 'trackingNumber'>>
  }

export type UpdateTicketStatusMutationVariables = Types.Exact<{
  input: Types.UpdateStatusInput
}>

export type UpdateTicketStatusMutation = { readonly __typename?: 'Mutation' } & {
  readonly updateTicketStatus: { readonly __typename?: 'UpdateStatusResponse' } & {
    readonly ticket: Types.Maybe<
      { readonly __typename?: 'Ticket' } & Pick<Types.Ticket, 'supportById' | 'supportByName' | 'supportByUbiquiti'> & {
          readonly device: { readonly __typename?: 'Device' } & Pick<Types.Device, 'warrantyStatus'>
        } & Shared_TicketFragment &
        Shared_Ticket_DetailsFragment &
        Shared_Ticket_CustomerFragment &
        Shared_Ticket_ManageFragment
    >
  }
}

export const Shared_Ticket_ManageFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shared_Ticket_Manage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statuses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'device' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rmaParentCategoryId' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'distributorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warrantyStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportByName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'destinationAddressId' } },
        ],
      },
    },
  ],
}
export const UpdateTicketStatusDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTicketStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateStatusInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTicketStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ticket' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shared_Ticket' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shared_Ticket_Details' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shared_Ticket_Customer' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shared_Ticket_Manage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'supportById' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'supportByName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'supportByUbiquiti' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'device' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'warrantyStatus' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shared_Ticket_Manage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statuses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'device' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rmaParentCategoryId' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'distributorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warrantyStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportByName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'destinationAddressId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shared_Ticket' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flow' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shared_Ticket_Details' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'device' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mac' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nsProductId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'salesOrderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'salesOrderUrl' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shared_Device' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'soldBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'soldById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportByName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportByReseller' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportByUbiquiti' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerFailureCategoryId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shippingCompany' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nsError' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipmentAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'country' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'replacementTrackingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerShippingCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerTrackingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerTrackingGenerated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userFiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyFiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'canCancelTicket' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nsTicketId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offerUiSupport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSupportedByUI' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'escalatedToUi' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEndCustomer' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shared_Device' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Device' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mac' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUnifiCare' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSellerInactive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRefurbished' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warrantyStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productImage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shared_Ticket_Customer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'picture' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'companyId' },
                        name: { kind: 'Name', value: 'id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipmentAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullShippingAddress' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FullShippingAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShippingAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
        ],
      },
    },
  ],
}
export type UpdateTicketStatusMutationFn = Apollo.MutationFunction<
  UpdateTicketStatusMutation,
  UpdateTicketStatusMutationVariables
>

/**
 * __useUpdateTicketStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTicketStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketStatusMutation, { data, loading, error }] = useUpdateTicketStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTicketStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTicketStatusMutation, UpdateTicketStatusMutationVariables>,
) {
  return Apollo.useMutation<UpdateTicketStatusMutation, UpdateTicketStatusMutationVariables>(
    UpdateTicketStatusDocument,
    baseOptions,
  )
}
export type UpdateTicketStatusMutationHookResult = ReturnType<typeof useUpdateTicketStatusMutation>
export type UpdateTicketStatusMutationResult = Apollo.MutationResult<UpdateTicketStatusMutation>
export type UpdateTicketStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateTicketStatusMutation,
  UpdateTicketStatusMutationVariables
>
