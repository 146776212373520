// This file is generated. Do not edit this file manually.
// This file should be regenerated any time backend types change
// using `yarn generate:graphql`

import type {
  ChatId,
  ChatMessageId,
  CompanyAddressId,
  CompanyId,
  Cursor,
  DeviceId,
  NetsuiteFailureCategoryId,
  FileId,
  NetsuiteProductId,
  ShipmentId,
  ShippingAddressId,
  TicketId,
} from 'rma-shared/types/brands'
import type { TicketStatus } from 'rma-shared/tickets'
import type { FileType } from 'rma-frontend-shared/utils/file'

export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  ChatId: ChatId
  ChatMessageId: ChatMessageId
  CompanyAddressId: CompanyAddressId
  CompanyId: CompanyId
  Cursor: Cursor
  Date: string
  DateTime: string
  DeviceId: DeviceId
  FailureCategoryId: NetsuiteFailureCategoryId
  FileId: FileId
  FlowType: any
  JSON: unknown
  JSONObject: Record<string, unknown>
  NotificationId: any
  NsProductId: NetsuiteProductId
  ShipmentId: ShipmentId
  ShipmentUId: any
  ShippingAddressId: ShippingAddressId
  TicketId: TicketId
  TicketStatus: TicketStatus
  TicketStatusActivityType: any
  Timestamp: any
  Upload: FileType
  UserId: any
}

export type AddressInput = {
  readonly city?: Maybe<Scalars['String']>
  readonly country?: Maybe<Scalars['String']>
  readonly isDefaultBilling?: Maybe<Scalars['Boolean']>
  readonly isDefaultShipping?: Maybe<Scalars['Boolean']>
  readonly phone?: Maybe<Scalars['String']>
  readonly state?: Maybe<Scalars['String']>
  readonly street?: Maybe<Scalars['String']>
  readonly zipcode?: Maybe<Scalars['String']>
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type Chat = {
  readonly __typename?: 'Chat'
  readonly createdAt: Maybe<Scalars['Date']>
  readonly id: Scalars['ChatId']
  readonly lastMessageSentAt: Maybe<Scalars['Date']>
  readonly message: Message
  readonly messageCount: Scalars['Int']
  readonly messages: ReadonlyArray<Message>
  readonly name: Maybe<Scalars['String']>
  readonly properties: Maybe<Scalars['JSON']>
  readonly unreadMessageCount: Scalars['Int']
  readonly users: ReadonlyArray<UserMention>
}

export type ChatMessageArgs = {
  messageId: Scalars['ChatMessageId']
}

export type ChatFileUpload = {
  readonly file: Scalars['Upload']
}

export type ChatMutations = {
  readonly __typename?: 'ChatMutations'
  readonly message: MessageMutations
  readonly readMessages: Scalars['Boolean']
  readonly sendMessage: Message
}

export type ChatMutationsMessageArgs = {
  messageId: Scalars['ChatMessageId']
}

export type ChatMutationsReadMessagesArgs = {
  parentId: Maybe<Scalars['ChatMessageId']>
}

export type ChatMutationsSendMessageArgs = {
  message: MessageInput
}

export type CompaniesFilter = {
  readonly status?: Maybe<ReadonlyArray<CompanyStatus>>
}

export type Company = {
  readonly __typename?: 'Company'
  readonly addresses: ReadonlyArray<CompanyAddress>
  readonly email: Maybe<Scalars['String']>
  readonly entityId: Scalars['String']
  readonly entityType: Scalars['String']
  readonly id: Scalars['CompanyId']
  readonly logo: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly nameLegal: Maybe<Scalars['String']>
  readonly parents: ReadonlyArray<Company>
  readonly resellers: ReadonlyArray<Company>
  readonly rmaAddresses: Maybe<ReadonlyArray<CompanyAddress>>
  readonly status: CompanyStatus
  readonly website: Maybe<Scalars['String']>
}

export type CompanyParentsArgs = {
  filter: Maybe<CompaniesFilter>
}

export type CompanyResellersArgs = {
  filter: Maybe<CompaniesFilter>
}

export type CompanyAddress = {
  readonly __typename?: 'CompanyAddress'
  readonly address1: Maybe<Scalars['String']>
  readonly address2: Maybe<Scalars['String']>
  readonly city: Maybe<Scalars['String']>
  readonly country: Maybe<Scalars['String']>
  readonly id: Maybe<Scalars['CompanyAddressId']>
  readonly isDefaultBilling: Scalars['Boolean']
  readonly isDefaultShipping: Scalars['Boolean']
  readonly phone: Maybe<Scalars['String']>
  readonly state: Maybe<Scalars['String']>
  readonly street: Maybe<Scalars['String']>
  readonly zipcode: Maybe<Scalars['String']>
}

export type CompanyAddressInput = {
  readonly address1?: Maybe<Scalars['String']>
  readonly address2?: Maybe<Scalars['String']>
  readonly city?: Maybe<Scalars['String']>
  readonly country?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['CompanyAddressId']>
  readonly isDefaultBilling: Scalars['Boolean']
  readonly isDefaultShipping: Scalars['Boolean']
  readonly phone?: Maybe<Scalars['String']>
  readonly state?: Maybe<Scalars['String']>
  readonly street?: Maybe<Scalars['String']>
  readonly zipcode?: Maybe<Scalars['String']>
}

export type CompanyShippingAddressInput = {
  readonly address: Scalars['String']
  readonly city: Scalars['String']
  readonly companyId: Scalars['CompanyId']
  readonly companyName: Scalars['String']
  readonly country: Scalars['String']
  readonly id: Scalars['CompanyAddressId']
  readonly phoneNumber: Scalars['String']
  readonly state?: Maybe<Scalars['String']>
  readonly zipcode: Scalars['String']
}

/** Copied from Direct */
export enum CompanyStatus {
  Active = 'active',
  Inactive = 'inactive',
  New = 'new',
}

export type CreateForwardingTicketsInput = {
  readonly deliveryAddress: CompanyShippingAddressInput
  readonly newCompanyFiles: ReadonlyArray<TicketsCompanyFilesInput>
  readonly returnAddress: CompanyShippingAddressInput
  readonly shipmentInfo: ShipmentInfo
  readonly shippingAddress?: Maybe<CreateTicketAddress>
  readonly shippingAddressId: Scalars['ShippingAddressId']
  readonly skipFileCopyIds: ReadonlyArray<TicketsFileIdsInput>
  readonly ticketIds: ReadonlyArray<Scalars['TicketId']>
}

export type CreateForwardingTicketsResponse = {
  readonly __typename?: 'CreateForwardingTicketsResponse'
  readonly shipmentBatchId: Scalars['ShipmentId']
  readonly tickets: ReadonlyArray<Ticket>
}

export type CreateTicketAddress = {
  readonly address1: Scalars['String']
  readonly city: Scalars['String']
  readonly companyName: Scalars['String']
  readonly country: Scalars['String']
  readonly email?: Maybe<Scalars['String']>
  readonly firstName: Scalars['String']
  readonly lastName: Scalars['String']
  readonly phoneNumber: Scalars['String']
  readonly state: Scalars['String']
  readonly zipcode: Scalars['String']
}

export type CreateTicketInput = {
  readonly companyId?: Maybe<Scalars['CompanyId']>
  readonly description: Scalars['String']
  readonly deviceId: Scalars['DeviceId']
  readonly devicePhotos?: Maybe<ReadonlyArray<Scalars['Upload']>>
  readonly failureCategoryId: Scalars['FailureCategoryId']
  readonly otherFiles?: Maybe<ReadonlyArray<Scalars['Upload']>>
  readonly proofOfPurchase?: Maybe<ReadonlyArray<Scalars['Upload']>>
  readonly reseller?: Maybe<ResellerCreateInput>
  readonly shippingAddress?: Maybe<CreateTicketAddress>
  readonly shippingAddressId: Scalars['ShippingAddressId']
  readonly userId?: Maybe<Scalars['UserId']>
}

export type CreateTicketResponse = {
  readonly __typename?: 'CreateTicketResponse'
  readonly ticket: Maybe<Ticket>
}

export enum CreatedVia {
  All = 'All',
  Direct = 'Direct',
  Rma = 'Rma',
  Var = 'Var',
}

export type Customer = {
  readonly __typename?: 'Customer'
  readonly email: Scalars['String']
  readonly firstName: Scalars['String']
  readonly fullname: Scalars['String']
  readonly id: Scalars['UserId']
  readonly lastName: Scalars['String']
  readonly picture: Maybe<Scalars['String']>
}

export type CustomerAddress = {
  readonly __typename?: 'CustomerAddress'
  readonly address1: Maybe<Scalars['String']>
  readonly address2: Maybe<Scalars['String']>
  readonly city: Maybe<Scalars['String']>
  readonly country: Maybe<Scalars['String']>
  readonly id: Scalars['ShippingAddressId']
  readonly isDefaultBilling: Scalars['Boolean']
  readonly isDefaultShipping: Scalars['Boolean']
  readonly name: Maybe<Scalars['String']>
  readonly phoneNumber: Maybe<Scalars['String']>
  readonly state: Maybe<Scalars['String']>
  readonly zipcode: Maybe<Scalars['String']>
}

export type CustomerScoreStats = {
  readonly __typename?: 'CustomerScoreStats'
  readonly change: Scalars['Float']
  readonly isDistributor: Scalars['Boolean']
  readonly name: Scalars['String']
  readonly reviews: Scalars['Int']
  readonly score: Scalars['Float']
  readonly url: Scalars['String']
}

/**
 * Device is specific instance of product/mac combo at a particular time.
 * Multiple entries of same product/mac combo can exist.
 */
export type Device = {
  readonly __typename?: 'Device'
  readonly coreItemCode: Scalars['String']
  readonly dateCode: Maybe<Scalars['String']>
  readonly id: Scalars['DeviceId']
  readonly isHandledByUbiquiti: Scalars['Boolean']
  readonly isRefurbished: Maybe<Scalars['Boolean']>
  readonly isSellerInactive: Scalars['Boolean']
  readonly isUnifiCare: Scalars['Boolean']
  readonly mac: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly nsProductId: Scalars['NsProductId']
  readonly parent: Maybe<Device>
  readonly product: Maybe<Product>
  readonly productImage: Maybe<Scalars['String']>
  readonly salesOrderId: Scalars['String']
  readonly salesOrderUrl: Scalars['String']
  readonly sellerId: Maybe<Scalars['String']>
  readonly warrantyStatus: WarrantyStatus
}

export type DistributorRatingStats = {
  readonly __typename?: 'DistributorRatingStats'
  readonly distributorId: Scalars['CompanyId']
  readonly distributorScore: Scalars['Float']
  readonly name: Scalars['String']
  readonly negativeReviewCount: Scalars['Int']
  readonly resellerScore: Scalars['Float']
  readonly resellersNegativeReviewCount: Scalars['Int']
  readonly resellersReviewCount: Scalars['Int']
  readonly resellersTicketCount: Scalars['Int']
  readonly reviewCount: Scalars['Int']
  readonly score: Scalars['Float']
  readonly ticketCount: Scalars['Int']
}

export type DistributorReassignedStats = {
  readonly __typename?: 'DistributorReassignedStats'
  readonly distributorId: Scalars['CompanyId']
  readonly distributorScore: Scalars['Float']
  readonly name: Scalars['String']
  readonly reassignedCount: Scalars['Int']
  readonly resellerScore: Scalars['Float']
  readonly resellersReassignedCount: Scalars['Int']
  readonly resellersTicketCount: Scalars['Int']
  readonly score: Scalars['Float']
  readonly scorePercents: Scalars['String']
  readonly ticketCount: Scalars['Int']
}

export type DistributorResponseStats = {
  readonly __typename?: 'DistributorResponseStats'
  readonly distributorId: Scalars['CompanyId']
  readonly distributorScore: Scalars['Float']
  readonly name: Scalars['String']
  readonly resellerScore: Scalars['Float']
  readonly resellersResponseTimeReadable: Scalars['String']
  readonly resellersTicketCount: Scalars['Int']
  readonly responseTimeReadable: Scalars['String']
  readonly score: Scalars['Float']
  readonly ticketCount: Scalars['Int']
}

export type EditMessageInput = {
  readonly content: Scalars['String']
  readonly important: Scalars['Boolean']
  readonly parentId?: Maybe<Scalars['ChatMessageId']>
}

export enum EscalatedType {
  AlreadyDisputed = 'AlreadyDisputed',
  Automatic = 'Automatic',
  CustomerSupportAccepted = 'CustomerSupportAccepted',
  CustomerSupportBackordered = 'CustomerSupportBackordered',
  CustomerSupportDeclined = 'CustomerSupportDeclined',
  CustomerSupportProcessing = 'CustomerSupportProcessing',
  Default = 'Default',
  Extended = 'Extended',
  None = 'None',
}

export enum FailureCategoryCorrectness {
  No = 'No',
  ProblemUnidentified = 'ProblemUnidentified',
  Yes = 'Yes',
}

export enum FailureCause {
  Customer = 'Customer',
  Design = 'Design',
  Manufacturer = 'Manufacturer',
  ShippingCompany = 'ShippingCompany',
}

export enum FailureDescriptionHelpfulness {
  No = 'No',
  Yes = 'Yes',
}

export type FailureStats = {
  readonly __typename?: 'FailureStats'
  readonly category: Scalars['String']
  readonly count: Scalars['Int']
}

export type File = {
  readonly __typename?: 'File'
  readonly encoding: Scalars['String']
  readonly filename: Scalars['String']
  readonly id: Scalars['FileId']
  readonly mimetype: Scalars['String']
  readonly uploadedPath: Scalars['String']
  readonly url: Scalars['String']
}

export type FiscalYear = {
  readonly quarter: Scalars['Int']
  readonly year: Scalars['Int']
}

export type IncomingPageInfo = {
  readonly __typename?: 'IncomingPageInfo'
  readonly endCursor: Maybe<Scalars['Cursor']>
  readonly hasNextPage: Scalars['Boolean']
  readonly limit: Scalars['Int']
  readonly offset: Scalars['Int']
  readonly total: Scalars['Int']
}

export type IncomingTicketResult = {
  readonly __typename?: 'IncomingTicketResult'
  readonly pageInfo: IncomingPageInfo
  readonly result: ReadonlyArray<Ticket>
}

export type InvitationRelationResponse = {
  readonly __typename?: 'InvitationRelationResponse'
  readonly childCompany: Maybe<Company>
  readonly expiresAt: Scalars['String']
  readonly joinedAt: Maybe<Scalars['String']>
  readonly parentCompany: Company
}

export enum LengthStatPeriod {
  Week1 = 'Week1',
  Week2 = 'Week2',
  Week3 = 'Week3',
  Week4 = 'Week4',
  Week5 = 'Week5',
  Week6 = 'Week6',
  Week7 = 'Week7',
  Week8Plus = 'Week8Plus',
}

export type LengthStats = {
  readonly __typename?: 'LengthStats'
  readonly count: Scalars['Int']
  readonly period: LengthStatPeriod
}

export type Message = {
  readonly __typename?: 'Message'
  readonly canManage: Scalars['Boolean']
  readonly chat: Chat
  readonly content: Scalars['String']
  readonly files: ReadonlyArray<File>
  readonly id: Scalars['ChatMessageId']
  readonly important: Scalars['Boolean']
  readonly messages: Maybe<ReadonlyArray<Message>>
  readonly parent: Maybe<Message>
  readonly readAt: Maybe<Scalars['Date']>
  readonly removedAt: Maybe<Scalars['Date']>
  readonly removedBy: Maybe<Scalars['String']>
  readonly sentAt: Scalars['Date']
  readonly sentBy: PartyUnion
  readonly sentByMe: Scalars['Boolean']
  readonly thread: Maybe<MessageThread>
  readonly updatedAt: Maybe<Scalars['Date']>
  readonly updatedBy: Maybe<Scalars['String']>
}

export type MessageInput = {
  readonly content: Scalars['String']
}

export type MessageMutations = {
  readonly __typename?: 'MessageMutations'
  readonly edit: Message
  readonly sendMessage: Message
  readonly uploadFiles: Maybe<Scalars['Boolean']>
}

export type MessageMutationsEditArgs = {
  message: EditMessageInput
}

export type MessageMutationsSendMessageArgs = {
  message: MessageInput
}

export type MessageMutationsUploadFilesArgs = {
  files: ReadonlyArray<ChatFileUpload>
}

export type MessageSender = {
  readonly __typename?: 'MessageSender'
  readonly name: Scalars['String']
  readonly picture: Maybe<Scalars['String']>
}

export type MessageThread = {
  readonly __typename?: 'MessageThread'
  readonly importantCount: Scalars['Int']
  readonly lastSentAt: Maybe<Scalars['Date']>
  readonly replyCount: Scalars['Int']
  readonly unreadMessageCount: Scalars['Int']
  readonly users: ReadonlyArray<Scalars['String']>
}

export type Mutation = {
  readonly __typename?: 'Mutation'
  readonly acceptTos: Scalars['Boolean']
  readonly changeTicketProduct: Maybe<Ticket>
  readonly chat: ChatMutations
  /** Create ticket to distributor based of existing customer or reseller ticket. */
  readonly createForwardingTickets: CreateForwardingTicketsResponse
  readonly createTicket: CreateTicketResponse
  readonly createTicketCreator: CreateTicketResponse
  readonly markTicketItemSent: Ticket
  readonly notificationsMarkAsRead: ReadonlyArray<Notification>
  readonly notificationsMarkAsUnread: Maybe<Notification>
  readonly notificationsRemove: Maybe<Notification>
  /** Rate experience and give feedback. */
  readonly rateExperience: Maybe<Ticket>
  readonly resellerAddressDelete: Scalars['Boolean']
  readonly resellerAddressUpdate: CompanyAddress
  /** Creates and sends an invite for a reseller. */
  readonly resellerCreateAndInvite: Company
  readonly resellerInfoUpdate: Maybe<Company>
  /** Callback to call when reseller is joining distributor based on invite. */
  readonly resellerJoin: Maybe<Scalars['Boolean']>
  /** Reseller sign-up form. */
  readonly resellerSignUp: Scalars['Boolean']
  readonly resendTicketToNetSuite: Ticket
  readonly reviveTicket: ReviveTicketResponse
  readonly ticketReassignToReseller: Maybe<Ticket>
  /** VAR and Direct users can update existing tickets. */
  readonly updateTicket: Ticket
  /** Update device. */
  readonly updateTicketDevice: Ticket
  readonly updateTicketReview: Ticket
  /** Managing life-cycle of the ticket. */
  readonly updateTicketStatus: UpdateStatusResponse
}

export type MutationAcceptTosArgs = {
  userId: Maybe<Scalars['UserId']>
}

export type MutationChangeTicketProductArgs = {
  productId: Scalars['NsProductId']
  sendToNs: Maybe<Scalars['Boolean']>
  ticketId: Scalars['TicketId']
}

export type MutationChatArgs = {
  chatId: Scalars['ChatId']
}

export type MutationCreateForwardingTicketsArgs = {
  input: CreateForwardingTicketsInput
}

export type MutationCreateTicketArgs = {
  input: CreateTicketInput
}

export type MutationCreateTicketCreatorArgs = {
  input: CreateTicketInput
}

export type MutationMarkTicketItemSentArgs = {
  ticketId: Scalars['TicketId']
}

export type MutationNotificationsMarkAsReadArgs = {
  ids: ReadonlyArray<Scalars['NotificationId']>
}

export type MutationNotificationsMarkAsUnreadArgs = {
  id: Scalars['NotificationId']
}

export type MutationNotificationsRemoveArgs = {
  id: Scalars['NotificationId']
}

export type MutationRateExperienceArgs = {
  input: RateExperienceInput
}

export type MutationResellerAddressDeleteArgs = {
  addressId: Scalars['CompanyAddressId']
}

export type MutationResellerAddressUpdateArgs = {
  input: CompanyAddressInput
}

export type MutationResellerCreateAndInviteArgs = {
  input: ResellerCreateAndInviteInput
}

export type MutationResellerInfoUpdateArgs = {
  input: ResellerUpdateInput
}

export type MutationResellerJoinArgs = {
  hash: Scalars['String']
}

export type MutationResellerSignUpArgs = {
  input: ResellerSignUpInput
}

export type MutationResendTicketToNetSuiteArgs = {
  notes: Scalars['String']
  ticketId: Scalars['TicketId']
}

export type MutationReviveTicketArgs = {
  ticketId: Scalars['TicketId']
}

export type MutationTicketReassignToResellerArgs = {
  companyId: Scalars['CompanyId']
  ticketId: Scalars['TicketId']
}

export type MutationUpdateTicketArgs = {
  input: UpdateTicketInput
}

export type MutationUpdateTicketDeviceArgs = {
  input: UpdateTicketDeviceInput
}

export type MutationUpdateTicketReviewArgs = {
  ticketReview: TicketReviewInput
}

export type MutationUpdateTicketStatusArgs = {
  input: UpdateStatusInput
}

export enum NetSuiteTicketStatus {
  Error = 'Error',
  Processing = 'Processing',
  Success = 'Success',
}

export type Notification = {
  readonly __typename?: 'Notification'
  readonly createdAt: Scalars['Timestamp']
  readonly data: Scalars['JSON']
  readonly id: Scalars['NotificationId']
  readonly seenAt: Maybe<Scalars['Timestamp']>
  readonly ticketId: Maybe<Scalars['TicketId']>
}

export enum NotificationType {
  ChatMessage = 'chat_message',
}

export enum NsTicketImportStatus {
  Done = 'done',
  Failed = 'failed',
  Importing = 'importing',
}

export type PageInfo = {
  readonly __typename?: 'PageInfo'
  readonly endCursor: Maybe<Scalars['Cursor']>
  readonly hasNextPage: Scalars['Boolean']
  readonly limit: Scalars['Int']
  readonly offset: Scalars['Int']
  readonly total: Scalars['Int']
  readonly typeTotals: Maybe<TicketTypeTotals>
}

export type PaginatedShipments = {
  readonly __typename?: 'PaginatedShipments'
  readonly items: ReadonlyArray<Shipment>
  readonly totalCount: Scalars['Int']
}

export type PartialCompany = {
  readonly __typename?: 'PartialCompany'
  readonly addresses: ReadonlyArray<CompanyAddress>
  readonly entityId: Scalars['String']
  readonly entityType: Scalars['String']
  readonly id: Scalars['CompanyId']
  readonly name: Scalars['String']
  readonly nameLegal: Maybe<Scalars['String']>
  readonly status: CompanyStatus
}

export type Party = {
  readonly __typename?: 'Party'
  readonly entityType: Maybe<Scalars['String']>
  readonly id: Scalars['Int']
  readonly name: Scalars['String']
  readonly referencedId: Scalars['ID']
  readonly type: Maybe<PartyType>
}

export enum PartyType {
  Company = 'Company',
  User = 'User',
}

/** Union type of party since party can be both a customer and a company */
export type PartyUnion = Company | Customer

export type Product = {
  readonly __typename?: 'Product'
  readonly coreItemCode: Scalars['String']
  readonly coreProductId: Scalars['String']
  readonly id: Scalars['NsProductId']
  readonly name: Scalars['String']
  readonly nsProductId: Maybe<Scalars['NsProductId']>
  readonly productCode: Scalars['String']
  readonly productImages: Maybe<ReadonlyArray<ProductImage>>
  /** Used to retrieve failure categories from NetSuite. */
  readonly rmaParentCategoryId: Maybe<Scalars['String']>
}

export type ProductCategory = {
  readonly __typename?: 'ProductCategory'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly path: Scalars['String']
}

export type ProductImage = {
  readonly __typename?: 'ProductImage'
  readonly iconUrl: Scalars['String']
  readonly id: Scalars['ID']
  readonly mediumUrl: Scalars['String']
  readonly originalUrl: Scalars['String']
  readonly smallUrl: Scalars['String']
  readonly type: Scalars['String']
}

export type ProductsByCategoryProduct = {
  readonly __typename?: 'ProductsByCategoryProduct'
  readonly description: Scalars['String']
  readonly id: Scalars['NsProductId']
  readonly imageUrl: Maybe<Scalars['String']>
  readonly name: Scalars['String']
}

export type ProductsByIdProduct = {
  readonly __typename?: 'ProductsByIdProduct'
  readonly description: Scalars['String']
  readonly id: Scalars['NsProductId']
  readonly imageUrl: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly rmaParentCategoryId: Maybe<Scalars['String']>
}

export type Query = {
  readonly __typename?: 'Query'
  readonly chat: Maybe<Chat>
  /** Retrieve all active companies */
  readonly companies: ReadonlyArray<PartialCompany>
  /** Retrieve single company by ID. */
  readonly company: Maybe<Company>
  /** Query companies database. */
  readonly companyLookup: ReadonlyArray<Company>
  /** Outgoing shipments from company to the distributor. */
  readonly companyShipments: ReadonlyArray<Shipment>
  /** Currently logged-in user. */
  readonly customer: Maybe<Customer>
  /** Lookup device by id. */
  readonly device: Maybe<Device>
  readonly getAllRmaErrorsFromProcessor: ReadonlyArray<RmaAllProcessorErrorData>
  readonly getRmaErrorsFromProcessor: ReadonlyArray<Maybe<RmaTicketProcessorErrorResponse>>
  /** Invitation information from hash. */
  readonly invitationRelation: Maybe<InvitationRelationResponse>
  /** Returns all companies assigned to the user. */
  readonly myCompanies: ReadonlyArray<Company>
  /** Returns currently selected company of VAR and Direct portal users. */
  readonly myCompany: Maybe<Company>
  readonly notificationsFetch: ReadonlyArray<Notification>
  readonly nsTicketImportStatus: NsTicketImportStatus
  /** 'showOnlyHandlingParties' will return only parties that are referenced in tickets table 'handled_by_company_id' field */
  readonly parties: Maybe<ReadonlyArray<Party>>
  /** Product categories. */
  readonly productCategories: ReadonlyArray<ProductCategory>
  /** Products within a category. */
  readonly productsByCategory: ReadonlyArray<ProductsByCategoryProduct>
  /** Products within a category. */
  readonly productsByIds: ReadonlyArray<ProductsByIdProduct>
  readonly productsByName: ReadonlyArray<Product>
  /** Products that are available without serial number */
  readonly productsWithoutSerial: ReadonlyArray<Product>
  /** Outgoing shipment from company to the distributor */
  readonly shipment: Shipment
  readonly shipments: PaginatedShipments
  /** Fetch single ticket by ID */
  readonly ticket: Maybe<Ticket>
  /** Misc stats for dashboard. */
  readonly ticketStats: TicketStats
  /**
   * Fetch user's outgoing tickets.
   *
   * This field is user both for customer's tickets and reseller/distributor tickets.
   */
  readonly tickets: TicketsResult
  /** List of tickets that should be forwarded to the distributor. */
  readonly ticketsPendingRma: TicketsResult
  readonly ticketsWithUnsentItems: ReadonlyArray<Ticket>
  readonly ubiquitiCompany: Company
  readonly validateFiles: Scalars['Boolean']
}

export type QueryChatArgs = {
  chatId: Scalars['ChatId']
}

export type QueryCompanyArgs = {
  id: Scalars['CompanyId']
}

export type QueryCompanyLookupArgs = {
  query: Scalars['String']
}

export type QueryCompanyShipmentsArgs = {
  query: Maybe<ShipmentsQuery>
}

export type QueryDeviceArgs = {
  deviceId: Scalars['DeviceId']
}

export type QueryGetAllRmaErrorsFromProcessorArgs = {
  endDate: Maybe<Scalars['Date']>
  startDate: Scalars['Date']
}

export type QueryGetRmaErrorsFromProcessorArgs = {
  rmaPortalIds: ReadonlyArray<Scalars['String']>
}

export type QueryInvitationRelationArgs = {
  hash: Scalars['String']
}

export type QueryPartiesArgs = {
  showOnlyHandlingParties: Maybe<Scalars['Boolean']>
  type: Maybe<PartyType>
}

export type QueryProductsByCategoryArgs = {
  categoryId: Scalars['ID']
}

export type QueryProductsByIdsArgs = {
  ids: ReadonlyArray<Scalars['ID']>
}

export type QueryProductsByNameArgs = {
  search: Scalars['String']
}

export type QueryShipmentArgs = {
  id: Maybe<Scalars['ShipmentId']>
  uid: Maybe<Scalars['String']>
}

export type QueryShipmentsArgs = {
  limit: Scalars['Int']
  offset: Scalars['Int']
  search: Maybe<Scalars['String']>
}

export type QueryTicketArgs = {
  id: Scalars['TicketId']
}

export type QueryTicketsArgs = {
  cursor: Maybe<Scalars['Cursor']>
  limit: Maybe<Scalars['Int']>
  query: Maybe<TicketsQuery>
}

export type QueryTicketsPendingRmaArgs = {
  cursor: Maybe<Scalars['Cursor']>
  filter: Maybe<TicketsPendingRmaFilter>
  limit: Maybe<Scalars['Int']>
}

export type QueryValidateFilesArgs = {
  files: ReadonlyArray<Scalars['Upload']>
  filesOptional: ReadonlyArray<Scalars['Upload']>
}

/** Query Open/Closed tickets. */
export enum QueryTicketType {
  All = 'All',
  Closed = 'Closed',
  Declined = 'Declined',
  InProgress = 'InProgress',
  Open = 'Open',
  Pending = 'Pending',
  Shipped = 'Shipped',
}

export type RateExperienceInput = {
  readonly feedback?: Maybe<Scalars['String']>
  readonly rating: Scalars['Int']
  readonly ticketId: Scalars['TicketId']
}

export type Rating = {
  readonly __typename?: 'Rating'
  readonly feedback: Scalars['String']
  /**
   * Rating is value between 0 and 1 which allows the UI to use whatever rating scale it desires (5 stars or 10 stars).
   * This all is reduced to float value.
   */
  readonly rating: Scalars['Float']
}

export type RatingStat = {
  readonly __typename?: 'RatingStat'
  readonly average: Scalars['Float']
  readonly count: Scalars['Int']
  readonly group: Scalars['Int']
}

export type RatingStats = {
  readonly __typename?: 'RatingStats'
  readonly average: Scalars['Float']
  readonly breakdown: ReadonlyArray<RatingStat>
  readonly count: Scalars['Int']
  readonly seller: Maybe<Seller>
}

export type ResellerAddressInput = {
  readonly address1?: Maybe<Scalars['String']>
  readonly address2?: Maybe<Scalars['String']>
  readonly city?: Maybe<Scalars['String']>
  readonly country?: Maybe<Scalars['String']>
  readonly isDefaultBilling: Scalars['Boolean']
  readonly isDefaultShipping: Scalars['Boolean']
  readonly phone?: Maybe<Scalars['String']>
  readonly state?: Maybe<Scalars['String']>
  readonly zipcode?: Maybe<Scalars['String']>
}

export type ResellerCreateAndInviteInput = {
  readonly companyParentId?: Maybe<Scalars['CompanyId']>
  readonly create: ResellerCreateInput
}

export type ResellerCreateInput = {
  readonly email?: Maybe<Scalars['String']>
  readonly logo?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly nameLegal?: Maybe<Scalars['String']>
  readonly suggestedAddress?: Maybe<Scalars['String']>
  readonly website?: Maybe<Scalars['String']>
}

export type ResellerInviteInput = {
  readonly companyChildId?: Maybe<Scalars['CompanyId']>
  readonly companyParentId: Scalars['CompanyId']
}

export type ResellerJoinInput = {
  /** Unique hash from URL to identify the invite. */
  readonly hash: Scalars['String']
  /** User's ID from SSO portal (account.ui.com) */
  readonly ssoId: Scalars['String']
}

export type ResellerSignUpInput = {
  readonly address: ResellerAddressInput
  readonly billingAddress?: Maybe<ResellerAddressInput>
  readonly hash: Scalars['String']
  readonly reseller: ResellerUpdateInput
  readonly sendConfirmationEmail?: Maybe<Scalars['Boolean']>
}

export type ResellerUpdateInput = {
  readonly email?: Maybe<Scalars['String']>
  readonly logo?: Maybe<Scalars['String']>
  readonly name?: Maybe<Scalars['String']>
  readonly nameLegal?: Maybe<Scalars['String']>
  readonly website?: Maybe<Scalars['String']>
}

export type ReviveTicketResponse = {
  readonly __typename?: 'ReviveTicketResponse'
  readonly ticket: Maybe<Ticket>
}

export type RmaAllProcessorErrorData = {
  readonly __typename?: 'RmaAllProcessorErrorData'
  readonly hadError: Scalars['Boolean']
  readonly isFinished: Scalars['Boolean']
  readonly jobName: Scalars['String']
  readonly lastErrorObject: Maybe<Scalars['JSON']>
  readonly rmaPortalId: Scalars['String']
}

export type RmaProcessorErrorData = {
  readonly __typename?: 'RmaProcessorErrorData'
  readonly hadError: Scalars['Boolean']
  readonly isFinished: Scalars['Boolean']
  readonly jobName: Scalars['String']
  readonly lastErrorObject: Maybe<Scalars['JSON']>
}

export type RmaTicketProcessorErrorResponse = {
  readonly __typename?: 'RmaTicketProcessorErrorResponse'
  readonly rmaErrorData: ReadonlyArray<RmaProcessorErrorData>
  readonly rmaPortalId: Scalars['String']
}

export enum Seller {
  Direct = 'Direct',
  Reseller = 'Reseller',
}

export type SellerCompany = {
  readonly __typename?: 'SellerCompany'
  readonly name: Maybe<Scalars['String']>
  readonly website: Maybe<Scalars['String']>
}

export type Shipment = {
  readonly __typename?: 'Shipment'
  readonly deliveryAddress: Maybe<ShippingAddress>
  readonly destination: Maybe<Scalars['String']>
  readonly deviceCount: Scalars['Int']
  readonly direction: ShipmentDirection
  readonly fromPartyName: Scalars['String']
  readonly id: Scalars['ShipmentId']
  readonly nsError: Maybe<Scalars['String']>
  readonly returnAddress: Maybe<ShippingAddress>
  readonly shippingCompany: Maybe<Scalars['String']>
  readonly status: ShipmentStatus
  readonly tickets: ReadonlyArray<Ticket>
  readonly toPartyName: Scalars['String']
  readonly toUbiquiti: Scalars['Boolean']
  readonly trackingNumber: Maybe<Scalars['String']>
  readonly uid: Scalars['ShipmentUId']
}

export enum ShipmentDirection {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing',
}

export type ShipmentInfo = {
  readonly shippingCompany: Scalars['String']
  readonly shippingCompanyOther?: Maybe<Scalars['String']>
  readonly trackingNumber: Scalars['String']
}

export enum ShipmentStatus {
  Arrived = 'Arrived',
  New = 'New',
  Shipped = 'Shipped',
}

export type ShipmentsFilter = {
  readonly direction?: Maybe<ShipmentDirection>
  readonly type?: Maybe<Scalars['String']>
}

export type ShipmentsQuery = {
  readonly search?: Maybe<Scalars['String']>
  readonly type?: Maybe<QueryTicketType>
}

export type ShippingAddress = {
  readonly __typename?: 'ShippingAddress'
  readonly address1: Scalars['String']
  readonly address2: Scalars['String']
  readonly city: Scalars['String']
  readonly companyName: Maybe<Scalars['String']>
  readonly country: Scalars['String']
  readonly email: Scalars['String']
  readonly name: Scalars['String']
  readonly phoneNumber: Scalars['String']
  readonly state: Scalars['String']
  readonly zipcode: Scalars['String']
}

/** SSO Roles supported by RMA backend */
export enum SsoUserRole {
  Direct = 'DIRECT',
  DirectAdmin = 'DIRECT_ADMIN',
  RmaCustomer = 'RMA_CUSTOMER',
  RmaFaeUser = 'RMA_FAE_USER',
  Var = 'VAR',
}

export type StatusesStats = {
  readonly __typename?: 'StatusesStats'
  readonly count: Scalars['Int']
  readonly status: TicketOverallStatus
}

export type Subscription = {
  readonly __typename?: 'Subscription'
  readonly chatUpdated: Chat
  readonly messageSent: Message
  readonly messageUpdated: Message
  readonly notificationAdded: Notification
  readonly threadMessageRemoved: Message
  readonly threadMessageSent: Message
  readonly threadMessageUpdated: Message
}

export type SubscriptionChatUpdatedArgs = {
  fetchActive?: Maybe<Scalars['Boolean']>
  fetchUnread?: Maybe<Scalars['Boolean']>
  filter: Maybe<Scalars['JSON']>
  userId: Scalars['Int']
}

export type SubscriptionMessageSentArgs = {
  chatId: Scalars['ChatId']
}

export type SubscriptionMessageUpdatedArgs = {
  chatId: Scalars['ChatId']
}

export type SubscriptionThreadMessageRemovedArgs = {
  chatId: Scalars['ChatId']
  parentId: Scalars['ChatMessageId']
}

export type SubscriptionThreadMessageSentArgs = {
  chatId: Scalars['ChatId']
  parentId: Scalars['ChatMessageId']
}

export type SubscriptionThreadMessageUpdatedArgs = {
  chatId: Scalars['ChatId']
  parentId: Scalars['ChatMessageId']
}

export type Ticket = {
  readonly __typename?: 'Ticket'
  readonly advancedFlowAvailable: Scalars['Boolean']
  readonly canCancelTicket: Scalars['Boolean']
  readonly chat: Chat
  readonly closedAt: Maybe<Scalars['DateTime']>
  /** All files submitted by the company except Status files. */
  readonly companyFiles: ReadonlyArray<File>
  readonly createdAt: Scalars['DateTime']
  /** Customer (end-user) who submitted the ticket. This returns value only if the submitter is a instance of User */
  readonly customer: Maybe<Customer>
  /** Failure category as set by the author of the ticket */
  readonly customerFailureCategoryId: Scalars['FailureCategoryId']
  readonly customerName: Scalars['String']
  readonly customerSentItemAt: Maybe<Scalars['DateTime']>
  readonly customerShippingCompany: Maybe<Scalars['String']>
  readonly customerTrackingGenerated: Scalars['Boolean']
  readonly customerTrackingNumber: Maybe<Scalars['String']>
  /** Description of the issue by the customer. */
  readonly description: Scalars['String']
  readonly destinationAddressId: Maybe<Scalars['ShippingAddressId']>
  readonly device: Device
  /** Company that is handling the ticket */
  readonly distributorId: Maybe<Scalars['CompanyId']>
  /** @deprecated Use data from reassign_distributor table instead, to impose less sources of truth */
  readonly escalatedAt: Maybe<Scalars['DateTime']>
  /** @deprecated Use data from reassign_distributor table instead, to impose less sources of truth */
  readonly escalatedToUi: Scalars['Boolean']
  /** Date and time until when this ticket can be forwarded to the distributor */
  readonly expiresAt: Maybe<Scalars['DateTime']>
  readonly flow: Maybe<Scalars['FlowType']>
  /** Description of the issue by the handler of the ticket. */
  readonly handlerDescription: Maybe<Scalars['String']>
  /** Failure category as set by the handler of the ticket (reseller or distributor) */
  readonly handlerFailureCategoryId: Maybe<Scalars['FailureCategoryId']>
  readonly id: Scalars['TicketId']
  readonly isDisabled: Scalars['Boolean']
  readonly isEndCustomer: Scalars['Boolean']
  readonly nextHandler: TicketHandler
  readonly nsError: Maybe<Scalars['String']>
  readonly nsTicketId: Maybe<Scalars['String']>
  readonly offerUiSupport: TicketSupport
  readonly parentId: Maybe<Scalars['TicketId']>
  readonly parentTicket: Maybe<Ticket>
  readonly rating: Maybe<Rating>
  readonly removedFromScoring: Scalars['Boolean']
  readonly replacementTrackingNumber: Maybe<Scalars['String']>
  readonly responseTime: Scalars['Int']
  readonly review: Maybe<TicketReview>
  readonly revivedCount: Scalars['Int']
  readonly shipment: Maybe<Shipment>
  readonly shipmentAddress: ShippingAddress
  readonly shipmentFromUid: Maybe<Scalars['ShipmentUId']>
  /** Shipments that this ticket is part of. */
  readonly shipments: ReadonlyArray<Shipment>
  readonly soldBy: Maybe<Scalars['String']>
  readonly soldById: Maybe<Scalars['CompanyId']>
  /** Current status of the ticket */
  readonly status: Scalars['TicketStatus']
  readonly statuses: Scalars['JSON']
  /** Party (User or Company) that submitted the ticket. */
  readonly submittedBy: PartyUnion
  readonly supportById: Scalars['CompanyId']
  readonly supportByName: Scalars['String']
  readonly supportByReseller: Scalars['Boolean']
  readonly supportByUbiquiti: Scalars['Boolean']
  readonly updatedAt: Scalars['DateTime']
  /** All files submitted by the user as part of this ticket. */
  readonly userFiles: ReadonlyArray<File>
  readonly warehouseAddress: ShippingAddress
  readonly warrantyStatus: Maybe<TicketWarrantyStatus>
}

export type TicketShipmentsArgs = {
  filter: Maybe<ShipmentsFilter>
}

export type TicketDistributionStats = {
  readonly __typename?: 'TicketDistributionStats'
  readonly count: Scalars['Int']
  readonly seller: Seller
}

export type TicketHandler = {
  readonly __typename?: 'TicketHandler'
  readonly id: Scalars['CompanyId']
  readonly name: Scalars['String']
}

/** While TicketStatus is specific status, the overall status is less granular and more generic (derived) status */
export enum TicketOverallStatus {
  /** InFulfilment, Shipped, Closed */
  Approved = 'Approved',
  /** ReturnCancelled */
  Cancelled = 'Cancelled',
  /** Declined */
  Declined = 'Declined',
  /** Submitted, Accepted, Processing */
  InProgress = 'InProgress',
}

export type TicketReview = {
  readonly __typename?: 'TicketReview'
  readonly correctFailureCategoryId: Maybe<Scalars['FailureCategoryId']>
  readonly customerSummary: Scalars['String']
  readonly failureCategoryCorrectness: Maybe<FailureCategoryCorrectness>
  readonly failureCause: Maybe<FailureCause>
  readonly failureDescriptionHelpfulness: Maybe<FailureDescriptionHelpfulness>
  readonly files: ReadonlyArray<File>
  readonly howAccurateFailureRating: Maybe<Scalars['String']>
  readonly notes: Scalars['String']
  readonly notesStage2: Maybe<Scalars['String']>
  readonly rootCause: Maybe<Scalars['String']>
  readonly rootCauseDetails1: Maybe<Scalars['String']>
  readonly rootCauseDetails2: Maybe<Scalars['String']>
  readonly rootCauseDetails3: Maybe<Scalars['String']>
  readonly ticketId: Scalars['TicketId']
  readonly unitBrokeness: Maybe<UnitBrokeness>
  readonly updatedBy: ReadonlyArray<Scalars['UserId']>
}

export type TicketReviewInput = {
  readonly correctFailureCategoryId?: Maybe<Scalars['FailureCategoryId']>
  readonly customerSummary: Scalars['String']
  readonly failureCategoryCorrectness?: Maybe<FailureCategoryCorrectness>
  readonly failureCause?: Maybe<FailureCause>
  readonly failureDescriptionHelpfulness?: Maybe<FailureDescriptionHelpfulness>
  readonly files?: Maybe<ReadonlyArray<Scalars['Upload']>>
  readonly filesRemoved: ReadonlyArray<Scalars['FileId']>
  readonly howAccurateFailureRating?: Maybe<Scalars['String']>
  readonly notes: Scalars['String']
  readonly notesStage2?: Maybe<Scalars['String']>
  readonly rootCause?: Maybe<Scalars['String']>
  readonly rootCauseDetails1?: Maybe<Scalars['String']>
  readonly rootCauseDetails2?: Maybe<Scalars['String']>
  readonly rootCauseDetails3?: Maybe<Scalars['String']>
  readonly stage: Scalars['Int']
  readonly ticketId: Scalars['TicketId']
  readonly unitBrokeness?: Maybe<UnitBrokeness>
}

/** A type to encapsulate all statistics related fields. */
export type TicketStats = {
  readonly __typename?: 'TicketStats'
  readonly customerScores: ReadonlyArray<CustomerScoreStats>
  readonly lengths: ReadonlyArray<LengthStats>
  readonly ratingDistribution: ReadonlyArray<RatingStats>
  readonly ratings: RatingStats
  readonly statuses: ReadonlyArray<StatusesStats>
  readonly ticketDistribution: ReadonlyArray<TicketDistributionStats>
}

/** A type to encapsulate all statistics related fields. */
export type TicketStatsRatingDistributionArgs = {
  ranges: Maybe<Scalars['Int']>
}

/** A type to encapsulate all statistics related fields. */
export type TicketStatsRatingsArgs = {
  ranges: Maybe<Scalars['Int']>
}

export type TicketSupport = {
  readonly __typename?: 'TicketSupport'
  readonly isSupportedByUI: Scalars['Boolean']
  readonly reason: Scalars['String']
  readonly type: EscalatedType
}

export type TicketTypeTotals = {
  readonly __typename?: 'TicketTypeTotals'
  readonly All: Scalars['Int']
  readonly Closed: Scalars['Int']
  readonly Declined: Scalars['Int']
  readonly InProgress: Scalars['Int']
  readonly Open: Scalars['Int']
  readonly Pending: Scalars['Int']
  readonly Shipped: Scalars['Int']
}

export enum TicketWarrantyStatus {
  Active = 'Active',
  Unvr = 'Unvr',
}

export type TicketsCompanyFilesInput = {
  readonly files: ReadonlyArray<Scalars['Upload']>
  readonly ticketId: Scalars['TicketId']
}

export type TicketsFileIdsInput = {
  readonly filesIds: ReadonlyArray<Scalars['String']>
  readonly ticketId: Scalars['TicketId']
}

export type TicketsPendingRmaFilter = {
  readonly isExpired?: Maybe<Scalars['Boolean']>
  readonly search?: Maybe<Scalars['String']>
  readonly ticketIds?: Maybe<ReadonlyArray<Scalars['String']>>
}

export type TicketsQuery = {
  readonly search?: Maybe<Scalars['String']>
  readonly type?: Maybe<QueryTicketType>
}

export type TicketsResult = {
  readonly __typename?: 'TicketsResult'
  readonly pageInfo: PageInfo
  readonly result: ReadonlyArray<Ticket>
}

export enum UnitBrokeness {
  No = 'No',
  NoFactoryReset = 'NoFactoryReset',
  Yes = 'Yes',
}

export type UpdateStatusInput = {
  readonly activity: Scalars['TicketStatus']
  readonly extraData?: Maybe<Scalars['JSONObject']>
  readonly files?: Maybe<ReadonlyArray<Scalars['Upload']>>
  readonly ticketId: Scalars['TicketId']
}

export type UpdateStatusResponse = {
  readonly __typename?: 'UpdateStatusResponse'
  readonly ticket: Maybe<Ticket>
}

export type UpdateTicketDeviceInput = {
  readonly sellerId?: Maybe<Scalars['CompanyId']>
  readonly ticketId: Scalars['TicketId']
}

export type UpdateTicketInput = {
  readonly handlerDescription?: Maybe<Scalars['String']>
  readonly handlerFailureCategoryId?: Maybe<Scalars['FailureCategoryId']>
  readonly ticketId: Scalars['TicketId']
}

export type User = {
  readonly __typename?: 'User'
  readonly fullname: Scalars['String']
  readonly id: Maybe<Scalars['UserId']>
  readonly picture: Maybe<Scalars['String']>
}

export type UserMention = {
  readonly __typename?: 'UserMention'
  readonly id: Maybe<Scalars['UserId']>
  readonly name: Scalars['String']
}

export enum WarrantyStatus {
  Active = 'Active',
  Expired = 'Expired',
  Unknown = 'Unknown',
}
