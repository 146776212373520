import { createApolloClient } from '@shared-ui/api/apollo'
import fragments from 'generated/fragments.json'
import type { QueryTicketArgs } from 'generated/graphql'
import { config } from './config'

const { possibleTypes } = fragments

type ApolloClient = ReturnType<typeof createApolloClient>

let client: ApolloClient = {} as ApolloClient
let clientLoaded = false

export function loadApolloClient() {
  if (clientLoaded) {
    return
  }

  clientLoaded = true
  client = createApolloClient({
    uri: `${config.baseUrl}/graphql`,
    wsUri: `${config.websocketsBaseUrl}/graphql`,
    possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          ticket(existing: unknown, { args, toReference }) {
            return toReference({ __typename: 'Ticket', id: (args as QueryTicketArgs).id })
          },
          chat: {
            keyArgs: ['chatId'],
            merge: false,
          },
        },
      },
      Mutation: {
        fields: {
          chat: {
            merge: false,
          },
        },
      },
      ChatMutation: {
        fields: {
          message: {
            merge: false,
          },
        },
      },
      Ticket: {
        fields: {
          rating: {
            merge: true,
          },
        },
      },
      TicketsResult: {
        fields: {
          pageInfo: {
            merge: false,
          },
        },
      },
    },
  })
}

export function getApolloClient() {
  return client
}

export function isApolloClientLoaded() {
  return clientLoaded
}
