import formatSerial from '@shared/lib/utils/format/serial'
import type { TableColumnConfig } from '@ubnt/ui-components'
import { Loader, Table, Text } from '@ubnt/ui-components'
import type { TableItemWithContext } from '@ubnt/ui-components/Table/TableTypes'
import { useEffect, useMemo, useState } from 'react'
import type { PaginationInfo } from 'rma-shared/types/table'
import type { IncomingTicket, IncomingTicketsFilter } from 'rma-shared/types/tickets'
import { QueryTicketType } from '../generated/graphql'
import { useGoTo, useRequest } from '../hooks'
import { useTicketsStore } from '../tickets/tickets-store'
import { LoaderContainer } from './Containers'
import { RemoteImage } from './Image'
import { PaginationControls } from './PaginationControls'
import { Rating } from './Rating'
import { StickyTableContainer, StickyTableWrap } from './StickyTable'
import { Customer, Device, FormattedDate, Status, TicketIdRow, TimeElapsed, Tracking } from './table-columns'

export function CustomerTicketsTable({ type, stickyHeadTop }: { type: QueryTicketType; stickyHeadTop?: string }) {
  const { tickets, search, pageInfo } = useTicketsStore()

  const [fetch, { loading, error }] = useRequest('incomingTickets')
  const [prevSearch, setPrevSearch] = useState('')
  const [filters, setFilters] = useState<IncomingTicketsFilter>()

  const goTo = useGoTo()

  useMemo(() => {
    if (prevSearch === window.location.search) {
      return
    }

    const newFilters = getFiltersFromUrl()
    setFilters(newFilters)
    setPrevSearch(window.location.search)
  }, [])

  const loadPage = async ({ limit, offset }: PaginationInfo) => {
    try {
      const result = await fetch({
        limit,
        offset,
        search,
        type,
        filters,
      })

      useTicketsStore.setState({
        tickets: result.tickets,
        totals: result.totals,
        pageInfo: result.pageInfo,
      })
    } catch (err) {
      //
    }
  }

  useEffect(() => {
    void loadPage({
      limit: pageInfo.limit,
      offset: 0,
      total: 0,
    })
  }, [search, type, filters])

  const columns = useMemo(() => columnsDefaultCallback(type), [type])

  return (
    <StickyTableWrap>
      {error || loading ? (
        <LoaderContainer>{error ? <Text size="inherit">Error loading data.</Text> : <Loader />}</LoaderContainer>
      ) : (
        <StickyTableContainer $headTopOffset={stickyHeadTop} $noItems={!tickets.length}>
          <Table
            rowHeight={50}
            headerHeight={50}
            columns={columns}
            items={tickets}
            disableColumnFilters
            onRowClick={(item) => goTo(`/${item.id}`)}
            renderPlaceholder={() => <Text size="inherit">No incoming tickets.</Text>}
            renderFooter={() => <PaginationControls pageInfo={pageInfo} loadPage={loadPage} itemsLabel="tickets" />}
          />
        </StickyTableContainer>
      )}
    </StickyTableWrap>
  )
}

function getFiltersFromUrl() {
  const urlParams = new URLSearchParams(window.location.search)
  const filters = urlParams.get('filters')
  if (!filters) {
    return undefined
  }

  const urlFilters = JSON.parse(atob(filters)) as IncomingTicketsFilter
  if (Object.keys(urlFilters).length === 0) {
    return undefined
  }

  return urlFilters
}

function columnsDefaultCallback(type: QueryTicketType): TableColumnConfig<IncomingTicket>[] {
  const timeColumn =
    type === QueryTicketType.Closed
      ? {
          id: 'closedAt' as keyof IncomingTicket,
          label: 'Completion time',
          growthFactor: 1,
          renderCell: (row: TableItemWithContext<IncomingTicket>) => <FormattedDate date={row.closedAt} />,
        }
      : {
          id: 'createdAt' as keyof IncomingTicket,
          label: 'Time elapsed',
          growthFactor: 1,
          renderCell: (row: TableItemWithContext<IncomingTicket>) => <TimeElapsed from={row.createdAt} />,
        }

  return [
    {
      id: 'id',
      label: 'Ticket no.',
      growthFactor: 1,
      renderCell: (row) => <TicketIdRow ticketId={row.id} />,
    },
    {
      id: 'sku',
      label: 'Device',
      growthFactor: 1,
      renderCell: (row) => <Device name={row.sku} image={<RemoteImage url={row.image} width={24} height={24} />} />,
    },
    {
      id: 'mac',
      label: 'MAC ID / Serial',
      growthFactor: 1,
      renderCell: (row) => formatSerial(row.mac),
    },
    {
      id: 'createdBy',
      label: 'Created By',
      growthFactor: 1,
      renderCell: (row) => <Customer id={row.id} name={row.createdBy} link={false} />,
    },
    timeColumn,
    {
      id: 'status',
      label: 'Status',
      growthFactor: 1,
      renderCell: (row) => <Status id={row.id} status={row.status} />,
    },
    {
      id: 'rating',
      label: 'Rating',
      growthFactor: 1,
      renderCell: (row) => {
        return <Rating readonly size="medium" width="98px" value={row.rating || 0} />
      },
    },
    {
      id: 'trackingNumber',
      label: 'Tracking',
      growthFactor: 1,
      sortable: true,
      renderCell: (row) => <Tracking ticketId={row.id} trackingNumber={row.trackingNumber} />,
    },
  ]
}
