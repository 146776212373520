import { FocusMonitorProvider, ToastProvider } from '@ubnt/ui-components'
import { ThemeProvider } from 'styled-components'
import { theme } from '../styles/theme'

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <FocusMonitorProvider>
      <ThemeProvider theme={theme}>
        <ToastProvider>{children}</ToastProvider>
      </ThemeProvider>
    </FocusMonitorProvider>
  )
}
